@import '@/components/mixins.scss';

.topbar {
  padding: 0 rem(30);
  min-height: 64px;
  height: 64px;
  border-bottom: 1px solid $border;
  color: $white;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  background-color: $ieem-blue;

  @media (max-width: $sm-max-width) {
    padding: 0 rem(16);
  }
}

// dark theme
[data-kit-theme='dark'] {
  .topbar {
    background: $ieem-blue;
    color: $white;
    border-bottom: 1px solid $dark-gray-4;
  }
}
